import * as React from "react";
import swal from "sweetalert";
import { useAuthProvider } from "../../core/authContext";
import {
  SubProperty,
  useGetSubPropertiesQuery,
  useDeleteSubPropertyMutation,
  useAddSubPropertyMutation,
} from "../../generated/graphql";
const useSubProperty = () => {
  const [editData, setEditData] = React.useState<SubProperty | null>(null);
  const [open, setOpen] = React.useState(false);
  const [SPLookup, setSPLookup] = React.useState<{
    [key: string]: SubProperty | null;
  }>();
  const handleClickEditOpen = (data: SubProperty | null) => {
    setEditData(data);
    setOpen(true);
  };
  const handleEditClose = () => {
    setEditData(null);
    setOpen(false);
  };

  const { getUser } = useAuthProvider();
  const property = getUser();

  // add allergens
  const {
    data: SubProperties,
    refetch: refetchSubProperty,
    loading: loadingSubProperty,
  } = useGetSubPropertiesQuery({
    fetchPolicy: "network-only",
    variables: {
      propertyID: property?.id,
    },
  });

  React.useEffect(() => {
    if (!SubProperties?.getSubProperties) return;
    let lookUp: { [key: string]: SubProperty | null } = {};
    for (let sp of SubProperties.getSubProperties) {
      lookUp[sp!.id] = sp;
    }
    setSPLookup(lookUp);
  }, [SubProperties?.getSubProperties]);

  const [deleteSubProperty] = useDeleteSubPropertyMutation();
  const [upsertSubProperty] = useAddSubPropertyMutation();

  // Delete Sub Property
  const handleDelete = async (id: string) => {
    try {
      await deleteSubProperty({
        variables: {
          id: id,
          propertyID: property?.id,
        },
      });
      swal({
        text: "Sub Property Deleted Successfully",
        icon: "success",
      });
      refetchSubProperty();
    } catch (err) {
      swal({
        text: `${err}`,
        icon: "error",
      });
    }
  };

  const handleAttachedSS = async (id: string, data: any) => {
    if (!id) return;

    const subProperty = SPLookup?.[id];
    if (!subProperty) return;
    const cleanSP = JSON.parse(JSON.stringify(subProperty, (name, val) => {
      if (name === '__typename') {
        delete val[name];
      } else {
        return val;
      }
    }));
    try {
      const res = await upsertSubProperty({
        variables: {
          subProperty: {
            id: cleanSP?.id || null,
            name: cleanSP?.name || "",
            sub_services: [...(cleanSP?.sub_services || []), ...data],
            property_id: cleanSP?.property_id,
          },
        },
      });
      if (res.data?.upsertSubProperty) {
        swal({
          text: "Sub Property Added Successfully",
          icon: "success",
        });
      }
      refetchSubProperty();
    } catch (err) {
      swal({
        text: `${err}`,
        icon: "error",
      });
    }
  };

  return {
    SubProperties: SubProperties?.getSubProperties || [],
    refetchSubProperty,
    handleClickEditOpen,
    handleEditClose,
    editData,
    propertyId: property?.id,
    open,
    setOpen,
    handleDelete,
    loadingSubProperty,
    SPLookup,
    handleAttachedSS,
  };
};

export default useSubProperty;
